import React from 'react';

const Edit = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M19.4615 3.20733C19.6718 2.99705 19.957 2.87891 20.2544 2.87891C20.5518 2.87891 20.837 2.99705 21.0473 3.20733C21.2576 3.41762 21.3757 3.70283 21.3757 4.00023C21.3757 4.29762 21.2576 4.58283 21.0473 4.79312L11.7432 14.0973L9.62878 14.6259L10.1574 12.5115L19.4615 3.20733ZM20.2544 0.878906C19.4266 0.878906 18.6327 1.20776 18.0473 1.79312L8.54731 11.2931C8.41915 11.4213 8.32823 11.5819 8.28427 11.7577L7.28427 15.7577C7.19908 16.0985 7.29893 16.459 7.54731 16.7073C7.79569 16.9557 8.15618 17.0556 8.49695 16.9704L12.497 15.9704C12.6728 15.9264 12.8334 15.8355 12.9615 15.7073L22.4615 6.20733C23.0469 5.62197 23.3757 4.82805 23.3757 4.00023C23.3757 3.1724 23.0469 2.37848 22.4615 1.79312C21.8762 1.20776 21.0822 0.878906 20.2544 0.878906ZM4.25439 3C3.45875 3 2.69568 3.31607 2.13307 3.87868C1.57046 4.44129 1.25439 5.20435 1.25439 6V20C1.25439 20.7957 1.57047 21.5587 2.13307 22.1213C2.69568 22.6839 3.45874 23 4.25439 23H18.2544C19.05 23 19.8131 22.6839 20.3757 22.1213C20.9383 21.5587 21.2544 20.7957 21.2544 20V13C21.2544 12.4477 20.8067 12 20.2544 12C19.7021 12 19.2544 12.4477 19.2544 13V20C19.2544 20.2652 19.149 20.5196 18.9615 20.7071C18.774 20.8946 18.5196 21 18.2544 21H4.25439C3.98918 21 3.73482 20.8946 3.54729 20.7071C3.35975 20.5196 3.25439 20.2652 3.25439 20V6C3.25439 5.73478 3.35975 5.48043 3.54729 5.29289C3.73482 5.10536 3.98918 5 4.25439 5H11.2544C11.8067 5 12.2544 4.55228 12.2544 4C12.2544 3.44772 11.8067 3 11.2544 3H4.25439Z'
      fill='currentColor'
    />
  </svg>
);

export default Edit;
