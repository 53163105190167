import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PostStyles from './styled';
import Category from '../Category';
import { Link } from 'react-router-dom';
import Picture from '../Picture';
import PlayVideoIcon from '../Icons/PlayVideo';
import Modal from '../Modal';
import Video from '../Video';

const Post = ({
  title = '',
  thumbnail = '',
  category = '',
  contentTag = '',
  slug = '',
  video = null,
  mediaType = '',
  ...props
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleClick = e => {
    if (!video || !video?.source || !video?.content) {
      return e;
    }

    e.preventDefault();
    e.stopPropagation();
    setModalOpen(true);
  };

  return (
    <PostStyles
      className={`post ${modalOpen && 'post--modal_open'}`}
      {...props}
    >
      <section
        className={`thumbnail ${
          mediaType === 'Video' ? 'thumbnail--darker' : ''
        }`}
      >
        <Picture
          src={
            thumbnail ||
            'https://images.ctfassets.net/y64yks2od8ab/3UefaL6DWW86Zf2Hea0yEt/e3281a883561105a363b6bbe05c3ead0/defaultPost.jpg?w=300'
          }
          alt={`${title} post image`}
        />
        {mediaType === 'Video' && <PlayVideoIcon />}
      </section>
      {category && <Category category={category} slug={contentTag} />}
      <p className='title'>
        <a href={`${slug}`} onClick={handleClick}>
          {title}
        </a>
      </p>

      {video && video.source && video.content && modalOpen && (
        <Modal onClose={() => setModalOpen(false)}>
          <Video {...video} />
        </Modal>
      )}
    </PostStyles>
  );
};

Post.propTypes = {
  title: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  slug: PropTypes.string,
  video: PropTypes.shape({
    source: PropTypes.string,
    content: PropTypes.string,
  }),
  mediaType: PropTypes.string,
};

export default Post;
