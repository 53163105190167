import React from 'react';

const PieChart = () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M9.1709 2.43037C9.3918 2.93656 9.16052 3.52597 8.65433 3.74686C7.30692 4.33485 6.12407 5.24395 5.20917 6.39469C4.29428 7.54543 3.6752 8.90277 3.40607 10.348C3.13694 11.7933 3.22594 13.2825 3.6653 14.6854C4.10466 16.0883 4.881 17.3623 5.92645 18.3959C6.97189 19.4294 8.25461 20.1912 9.66246 20.6145C11.0703 21.0378 12.5604 21.1098 14.0025 20.8242C15.4446 20.5385 16.7948 19.904 17.935 18.976C19.0752 18.0481 19.9708 16.8549 20.5433 15.5009C20.7584 14.9922 21.3452 14.7542 21.8538 14.9693C22.3625 15.1844 22.6005 15.7711 22.3854 16.2798C21.6856 17.9347 20.5911 19.393 19.1975 20.5272C17.8039 21.6614 16.1537 22.437 14.3911 22.7861C12.6285 23.1351 10.8073 23.0472 9.08657 22.5298C7.36587 22.0124 5.7981 21.0814 4.52033 19.8181C3.24257 18.5549 2.29371 16.9978 1.75671 15.2831C1.21971 13.5685 1.11093 11.7483 1.43987 9.9819C1.76881 8.21546 2.52546 6.55649 3.64366 5.15003C4.76187 3.74357 6.20758 2.63245 7.85441 1.9138C8.3606 1.69291 8.95001 1.92418 9.1709 2.43037Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M11.5473 1.29289C11.7348 1.10536 11.9892 1 12.2544 1C13.6989 1 15.1293 1.28452 16.4639 1.83733C17.7985 2.39013 19.0111 3.20038 20.0326 4.22183C21.054 5.24327 21.8643 6.4559 22.4171 7.79048C22.9699 9.12506 23.2544 10.5555 23.2544 12C23.2544 12.5523 22.8067 13 22.2544 13H12.2544C11.7021 13 11.2544 12.5523 11.2544 12V2C11.2544 1.73478 11.3598 1.48043 11.5473 1.29289ZM13.2544 3.05573V11H21.1987C21.1049 10.1614 20.8935 9.33849 20.5693 8.55585C20.117 7.46392 19.4541 6.47177 18.6184 5.63604C17.7826 4.80031 16.7905 4.13738 15.6985 3.68508C14.9159 3.3609 14.093 3.14949 13.2544 3.05573Z'
      fill='currentColor'
    />
  </svg>
);

export default PieChart;
