import React from 'react';

export default () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M15.9174 3.77329C14.1446 2.9834 12.164 2.78771 10.271 3.21542C8.3779 3.64314 6.67381 4.67133 5.41285 6.14666C4.15188 7.62199 3.4016 9.4654 3.27391 11.402C3.14621 13.3386 3.64794 15.2645 4.70426 16.8927C5.76058 18.5208 7.31491 19.7638 9.13542 20.4364C10.9559 21.109 12.9451 21.1751 14.8062 20.6248C16.6674 20.0745 18.3007 18.9374 19.4628 17.3829C20.6248 15.8285 21.2533 13.9401 21.2544 11.9993V11.0799C21.2544 10.5276 21.7021 10.0799 22.2544 10.0799C22.8067 10.0799 23.2544 10.5276 23.2544 11.0799V11.9999C23.253 14.3719 22.4849 16.6806 21.0647 18.5804C19.6444 20.4803 17.648 21.8702 15.3733 22.5427C13.0986 23.2153 10.6674 23.1345 8.44231 22.3125C6.21724 21.4904 4.31751 19.9711 3.02645 17.9812C1.73539 15.9913 1.12216 13.6373 1.27824 11.2704C1.43431 8.90346 2.35132 6.6504 3.8925 4.84722C5.43368 3.04404 7.51646 1.78736 9.8302 1.2646C12.1439 0.741837 14.5647 0.981007 16.7314 1.94644C17.2359 2.17122 17.4626 2.76239 17.2378 3.26687C17.013 3.77134 16.4219 3.99807 15.9174 3.77329Z'
      fill='currentColor'
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M22.9611 3.29254C23.3519 3.68287 23.3522 4.31603 22.9619 4.70675L12.9619 14.7168C12.7744 14.9044 12.5199 15.0099 12.2546 15.01C11.9893 15.0101 11.7349 14.9047 11.5473 14.7171L8.54729 11.7171C8.15676 11.3266 8.15676 10.6934 8.54729 10.3029C8.93781 9.91237 9.57098 9.91237 9.9615 10.3029L12.254 12.5954L21.5469 3.29325C21.9373 2.90253 22.5704 2.90221 22.9611 3.29254Z'
      fill='currentColor'
    />
  </svg>
);
