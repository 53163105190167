import styled from 'styled-components'

const PlayVideoStyles = styled.div`

    svg {
        @media(max-width: ${props => props.theme.breakpoints.tablet}) {
            width: 50px;
        }
    }
`

export default PlayVideoStyles