import React from 'react';
import PropTypes from 'prop-types';
import { asyncComponent } from '@jaredpalmer/after';
import CategoryStyles from './styled';
import PieChart from '../icons/pie-chart';
import Edit from '../icons/edit';
import Play from '../icons/play';
import Quiz from '../icons/CheckCircle';

const categoryLookup = {
  guide: {
    icon: <PieChart />,
    title: 'Guide',
  },
  article: {
    icon: <Edit />,
    title: 'Article',
  },
  infographic: {
    icon: <PieChart />,
    title: 'Infographic',
  },
  video: {
    icon: <Play />,
    title: 'Video',
  },
  quiz: {
    icon: <Quiz />,
    title: 'Quiz',
  },
};

const Category = ({ slug, category }) => {
  const icon =
    categoryLookup[slug.toLowerCase()]?.icon || categoryLookup.article.icon;
  const title = categoryLookup[slug.toLowerCase()]?.title || slug;

  return (
    <CategoryStyles className='category'>
      {icon}
      {title}
      {category && <span> | {category}</span>}
    </CategoryStyles>
  );
};

Category.propTypes = {
  category: PropTypes.string,
  slug: PropTypes.string.isRequired,
};

export default Category;
