import React from 'react';
import shortUuid from '../../../util/shortUuid';
import Styles from './PlayVideo.styled';

export default ({ backgroundColor = '#FFFFFF', color = '#004B8C' }) => {
  const uuid = shortUuid();

  return (
    <Styles>
      <svg
        width='154'
        height='161'
        viewBox='0 0 154 161'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter={`url(#${uuid})`}>
          <path
            d='M77 105C101.853 105 122 85.0767 122 60.5C122 35.9233 101.853 16 77 16C52.1472 16 32 35.9233 32 60.5C32 85.0767 52.1472 105 77 105Z'
            fill={backgroundColor}
          />
          <path
            d='M87.0416 59.7036C87.5691 60.1038 87.5691 60.8968 87.0416 61.297L75.4356 70.1014C74.7771 70.6009 73.8313 70.1312 73.8313 69.3047V51.696C73.8313 50.8695 74.7771 50.3998 75.4356 50.8993L87.0416 59.7036Z'
            fill={color}
          />
        </g>
        <defs>
          <filter
            id={uuid}
            x='0'
            y='0'
            width='154'
            height='161'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset />
            <feGaussianBlur stdDeviation='0.5' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
            />
            <feBlend
              mode='normal'
              in2='BackgroundImageFix'
              result='effect1_dropShadow'
            />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset dy='2' />
            <feGaussianBlur stdDeviation='4' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
            />
            <feBlend
              mode='normal'
              in2='effect1_dropShadow'
              result='effect2_dropShadow'
            />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset dy='8' />
            <feGaussianBlur stdDeviation='12' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
            />
            <feBlend
              mode='normal'
              in2='effect2_dropShadow'
              result='effect3_dropShadow'
            />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
            />
            <feOffset dy='24' />
            <feGaussianBlur stdDeviation='16' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.04 0'
            />
            <feBlend
              mode='normal'
              in2='effect3_dropShadow'
              result='effect4_dropShadow'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect4_dropShadow'
              result='shape'
            />
          </filter>
        </defs>
      </svg>
    </Styles>
  );
};
