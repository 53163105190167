import React from 'react';

export default () => (
  <svg
    width='25'
    height='24'
    viewBox='0 0 25 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M4.77521 2.12229C5.09628 1.947 5.48744 1.96101 5.79515 2.15882L19.7952 11.1588C20.0814 11.3428 20.2544 11.6597 20.2544 12C20.2544 12.3403 20.0814 12.6572 19.7952 12.8412L5.79515 21.8412C5.48744 22.039 5.09628 22.053 4.77521 21.8777C4.45413 21.7024 4.25439 21.3658 4.25439 21V3C4.25439 2.63419 4.45413 2.29758 4.77521 2.12229ZM6.25439 4.83167V19.1683L17.4051 12L6.25439 4.83167Z'
      fill='currentColor'
    />
  </svg>
);
